<template>
    <div class="achieveCompleteRate">
        <div class="search" style="display: block">
            <div class="search-w">
                <label class="search-label">请选择搜索范围</label>
                <a-tree-select
                    class="search-style"
                    style="width: 300px"
                    v-model="OrgIds"
                    :tree-data="treeData"
                    tree-checkable
                    :maxTagCount="1"
                    placeholder="请选择搜索范围"
                    :show-checked-strategy="SHOW_PARENT"
                />
            </div>

            <div class="search-w">
                <label class="search-label">目标</label>
                <a-select
                    class="search-style"
                    v-model="TargetLevel"
                    style="width: 200px"
                    placeholder="目标"
                >
                    <a-select-option value="1">一标</a-select-option>
                    <a-select-option value="2">二标</a-select-option>
                    <a-select-option value="3">三标</a-select-option>
                </a-select>
            </div>
            <div class="search-w">
                <label class="search-label">类型</label>
                <a-select
                    class="search-style"
                    @change="handleChange"
                    v-model="PartLevel"
                    style="width: 200px"
                    v-if="class_category"
                    placeholder="类型"
                >
                    <a-select-option
                        v-for="(item, index) in class_category"
                        :value="index"
                    >
                        {{ item }}
                    </a-select-option>
                </a-select>
            </div>
            <div class="search-w">
                <label class="search-label">时间区间</label>
                <a-month-picker
                    class="search-style"
                    :default-value="moment(StartMonth, 'YYYY-MM')"
                    style="margin-right: 8px"
                    :value="timeValue1"
                    @change="_startSignDate"
                    placeholder="开始时间"
                />

                <a-month-picker
                    class="search-style"
                    :default-value="moment(EndMonth, 'YYYY-MM')"
                    :value="timeValue2"
                    @change="_endSignDate"
                    placeholder="结束时间"
                />
            </div>

            <a-button class="search-style" style="margin-right: 10px" @click="_clear"
            >重置
            </a-button
            >
            <a-button type="primary" @click="_search">查询</a-button>
        </div>

        <p style="margin-bottom: 15px;font-size: 14px;" v-if="timeSchedule">
            时间进度: <span style="font-weight: bold">{{ timeSchedule }}</span>
        </p>

        <a-table
            :pagination="false"
            :columns="columns"
            bordered
            :data-source="data"
        >
            <div slot="PlanAchievement" slot-scope="text, record, index">
                {{ toThousands(record.PlanAchievement) }}
            </div>
            <div slot="RealAchievement" slot-scope="text, record, index">
                {{ toThousands(record.RealAchievement) }}
            </div>
            <div slot="GrossAchievement" slot-scope="text, record, index">
                {{ toThousands(record.GrossAchievement) }}
            </div>

            <div slot="CompleteRealAchievementRate" slot-scope="text, record, index">
                {{ record.CompleteRealAchievementRate }}%
            </div>
            <div slot="CompleteGrossAchievementRate" slot-scope="text, record, index">
                {{ record.CompleteGrossAchievementRate }}%
            </div>
        </a-table>

        <p style="margin-top: 20px">此数据与业绩总表(新)的数据一致</p>

        <div v-show="spinning" style="position:fixed;left:50%;">
            <a-spin style="margin: 150px 0%;">
                <a-icon
                    slot="indicator"
                    type="loading"
                    :spinning="spinning"
                    style="font-size: 44px;color:red;"
                    spin
                />
            </a-spin>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'achieveCompleteRate',
    data() {
        return {
            userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
            columns: [
                {
                    title: '中心',
                    dataIndex: 'Name',
                    key: 'Name',
                    align: 'left',
                    width: 260,
                    fixed: 'left'
                },
                {
                    title: '目标业绩',
                    // dataIndex: 'PlanAchievement',
                    key: 'PlanAchievement',
                    align: 'center',
                    sorter: (a, b) => a.PlanAchievement - b.PlanAchievement,
                    scopedSlots: {customRender: 'PlanAchievement'}
                },
                {
                    title: '净业绩',
                    // dataIndex: 'RealAchievement',
                    key: 'RealAchievement',
                    align: 'center',
                    sorter: (a, b) => a.RealAchievement - b.RealAchievement,
                    scopedSlots: {customRender: 'RealAchievement'}
                },
                {
                    title: '毛业绩',
                    // dataIndex: 'GrossAchievement',
                    key: 'GrossAchievement',
                    align: 'center',
                    sorter: (a, b) => a.GrossAchievement - b.GrossAchievement,
                    scopedSlots: {customRender: 'GrossAchievement'}
                },
                {
                    title: '净业绩完成率',
                    key: 'CompleteRealAchievementRate',
                    align: 'center',
                    sorter: (a, b) =>
                        a.CompleteRealAchievementRate - b.CompleteRealAchievementRate,
                    scopedSlots: {customRender: 'CompleteRealAchievementRate'}
                },

                {
                    title: '毛业绩完成率',
                    key: 'CompleteGrossAchievementRate',
                    align: 'center',
                    sorter: (a, b) =>
                        a.CompleteGrossAchievementRate - b.CompleteGrossAchievementRate,
                    // defaultSortOrder: 'asscend',
                    scopedSlots: {customRender: 'CompleteGrossAchievementRate'}
                }
            ],
            data: [],

            userinfo: JSON.parse(sessionStorage.getItem('userInfo')),
            treeData: [
                {
                    title: 'Node1',
                    value: '7520669f3b20008c6a6df71e9cebc2f9',
                    key: '3',
                    children: [
                        {
                            title: 'Child Node1',
                            value: '7520669f3b20008c6a6df71e9cebc2f944',
                            key: '4'
                        }
                    ]
                },
                {
                    title: 'Node2',
                    value: '6',
                    key: '7',
                    children: [
                        {
                            title: 'Child Node3',
                            value: '7520669f3b20008c6a6df71e9cebc2f9sdfs',
                            key: '8'
                        },
                        {
                            title: 'Child Node4',
                            value: '7520669f3b20008c6a6df71e9cebc2f9wee',
                            key: '9'
                        },
                        {
                            title: 'Child Node5',
                            value: '7520669f3b20008c6a6df71e9cebc2f9weffds',
                            key: '1'
                        }
                    ]
                }
            ],
            timeValue1: undefined,
            timeValue2: undefined,

            OrgIds: [],

            SHOW_PARENT: 'SHOW_ALL',
            class_category: {
                division: '事业部',
                area: '大区',
                city: '城市',
                school: '中心',
                user: '顾问'
            },

            PartLevel: '',
            TargetLevel: '1',
            StartMonth: this.addDate(),
            EndMonth: this.addDate(),
            spinning: false,
            timeSchedule: ''
        }
    },
    created() {
        this._treeData()
    },
    methods: {
        moment,
        _treeData() {
            let self = this
            this.$axios.get(
                1090,
                {
                    UserId: self.userinfo.uid
                },
                res => {
                    self.treeData = res.data
                }
            )
        },

        addDate(type) {
            let nowDate = new Date()
            let date = {
                year: nowDate.getFullYear(),
                month: nowDate.getMonth() + 1,
                date: nowDate.getDate()
            }
            let systemDate =
                date.year + '-' + (date.month >= 10 ? date.month : '0' + date.month)

            return systemDate
        },
        toThousands(num) {
            var num = (num || 0).toString(),
                result = ''

            while (num.length > 3) {
                result = ',' + num.slice(-3) + result
                num = num.slice(0, num.length - 3)
            }

            if (num) {
                result = num + result
            }
            return result
        },

        handleChange(value) {
            this.PartLevel = value
        },
        loadData() {
            this.spinning = true
            let self = this
            this.$message.loading('加载中...')
            this.$axios.post(
                1123,
                {
                    OrgIds: JSON.stringify(self.OrgIds),
                    StartMonth: self.StartMonth,
                    EndMonth: self.EndMonth,
                    PartLevel: self.PartLevel,
                    TargetLevel: self.TargetLevel
                },
                res => {
                    self.spinning = false
                    if (res.data.code == 1) {
                        self.data = res.data.data
                        self.timeSchedule = res.data.progress
                    } else {
                        self.data = []
                        this.$message.error(res.data.msg)
                    }
                    this.$message.destroy()
                }
            )
        },
        _search() {
            if (this.startSignDate) {
                if (!this.endSignDate) {
                    this.$message.error('请选择结束时间')
                    return false
                }
            }
            if (this.endSignDate) {
                if (!this.startSignDate) {
                    this.$message.error('请选择开始时间')
                    return false
                }
                let formatDate1 = new Date(this.startSignDate)
                let formatDate2 = new Date(this.endSignDate)
                if (formatDate1 > formatDate2) {
                    this.$message.error('开始时间不能大于结束时间')
                    return false
                }
            }
            if (!this.PartLevel) {
                this.$message.error('请选择类型')
                return false
            }
            this.loadData()
        },
        _clear() {
            this.PartLevel = ''
            this.OrgIds = []
            this.timeValue1 = undefined
            this.timeValue2 = undefined
            this.TargetLevel = '1'
            this.StartMonth = this.addDate()
            this.EndMonth = this.addDate()
        },
        _startSignDate(value) {
            this.timeValue1 = value
            let date1 = new Date(value._d)
            this.StartMonth =
                date1.getFullYear() +
                '-' +
                (date1.getMonth() + 1 < 10
                    ? '0' + (date1.getMonth() + 1)
                    : date1.getMonth() + 1)
        },
        _endSignDate(value) {
            this.timeValue2 = value
            let date2 = new Date(value._d)
            this.EndMonth =
                date2.getFullYear() +
                '-' +
                (date2.getMonth() + 1 < 10
                    ? '0' + (date2.getMonth() + 1)
                    : date2.getMonth() + 1)
        }
    }
}
</script>

<style scoped lang="less">
.achieveCompleteRate {
    background: #ffffff;
    margin-top: 15px;
    min-height: 800px;
    padding: 15px;
}

.search {
    margin-bottom: 20px;

    .search-w {
        display: inline-block;
        margin-right: 15px;

        .search-label {
            width: 100px;
            margin-right: 8px;
        }

        .search-style {
            display: inline-block;
            width: 120px;
            /*margin-right: 15px;*/
        }
    }
}
</style>
